
<template>
  <div class="main">
    <div v-if="load" class="load">
      <div class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div v-if="Nload" class="load" style="background-color: white">
      <div class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
    <div v-if="alert && !already" class="alert">
      <div class="alr">
        <div class="x" v-on:click="hide">X</div>
        <div v-if="success == 'true'">
          <div
            style="
              width: fit-content;
              margin-left: auto;
              margin-top: 50px;
              margin-right: 50px;
              max-width: 300px;
            "
          >
            <div class="childd alrTitle">Commencer dés le début</div>
            <div class="childd" style="margin-bottom: 8px">
              <button v-on:click="start" style="margin-bottom: 0" class="startt">
                Commencer {{ lang != 9 ? lblNv : EnlblNv }}
              </button>
            </div>

            <div class="childd alrTitle">ou bien Choisir un niveau</div>
            <div class="childd Mradio">
              <span
                v-for="nv in niveaux"
                :key="nv.idNiveau"
                :style="
                  nv.idNiveau == 5 || nv.idNiveau == 11 || nv.idNiveau == 15
                    ? 'display: none'
                    : ''
                "
              >
                <input
                  style="width: fit-content"
                  name="nv"
                  type="radio"
                  v-model="niv"
                  :value="nv.idNiveau"
                />
                {{ lang != 9 ? nv.LibelleNiveau : nv.EnLibelleNiveau }}
              </span>
            </div>
            <div class="childd" style="margin-bottom: 8px">
              <button
                :disabled="niv != '' ? false : true"
                v-on:click="startNv"
                style="margin-bottom: 0"
                class="startt"
              >
                Commencer le test
              </button>
            </div>
            <div class="durre">Durée de test : {{ time }}min</div>
          </div>
          <div
            style="
              margin: 0;
              position: absolute;
              bottom: 45px;
              padding: 0 60px;
              color: yellow;
              font-weight: bold;
              font-size: 18px;
            "
            class="childd"
          >
            Afin d'évaluer votre niveau de façon précise, veuillez faire appel à vos
            seules connaissances durant tout ce test <br />
          </div>
        </div>
        <div v-if="success == 'false'">
          <div
            style="width: 40%; margin-left: auto; margin-top: 50px; margin-right: 50px"
            class="childd alrTitle"
          >
            vous ne pouvez pas passer le test de {{ lblNv }} parce-que vous n'avez pas
            réussi sur le niveau précedent
          </div>
        </div>
      </div>
    </div>
    <div v-if="alert && already" class="alert">
      <div class="alr">
        <div class="x" v-on:click="hide">X</div>
        <div
          style="width: 40%; margin-left: auto; margin-top: 50px; margin-right: 50px"
          class="childd alrTitle"
        >
          vous avez déjà passé le test,pour en savoir plus
          <a href="https://victory-partners.ma/contactez-nous/" style="color: red"
            >contactez-nous
          </a>
        </div>
      </div>
    </div>
    <div v-if="cant" class="alert">
      <div class="alr">
        <div class="x" v-on:click="hideCant">X</div>
        <div
          style="width: 40%; margin-left: auto; margin-top: 50px; margin-right: 50px"
          class="childd alrTitle"
        >
          vous ne pouvez pas passez ce niveau au départ, choisir un autre niveau
        </div>
      </div>
    </div>
    <div class="cnttt">
      <div>
        <h1 v-if="niveau == 3" class="title">
          Passer le test de
          <span style="color: red" v-if="lang != 9"
            >{{matiere.LibelleMatiere}} <span style="color: blue">Juniors</span></span
          ><span style="color: red" v-if="lang == 9"
            >{{matiere.LibelleMatiere}} <span style="color: blue">Kids</span></span
          >
          (votre age : {{ age }}ans)
        </h1>
        <h1 v-if="niveau == 4" class="title">
          Passer le test de
          <span style="color: red" v-if="lang != 9"
            >{{matiere.LibelleMatiere}} <span style="color: blue">Adolescents</span></span
          ><span style="color: red" v-if="lang == 9"
            >{{matiere.LibelleMatiere}} <span style="color: blue">Teenagers</span></span
          >(votre age : {{ age }}ans)
        </h1>
        <h1 v-if="niveau == 5" class="title">
          Passer le test de
          <span style="color: red" v-if="lang != 9"
            >{{matiere.LibelleMatiere}} <span style="color: blue">Adultes</span></span
          ><span style="color: red" v-if="lang == 9"
            >{{matiere.LibelleMatiere}} <span style="color: blue">Adults</span></span
          >(votre age : {{ age }}ans)
        </h1>
        <div style="text-align: center; margin: 20px auto">
          <img
            v-on:click="show"
            v-if="lang != 9"
            id="Mimg"
            src="@/assets/mainFr.jpg"
            alt=""
          />
          <img
            v-on:click="show"
            v-if="lang == 9"
            id="Mimg"
            src="@/assets/mainEn.jpg"
            alt=""
          /><br />
          <button v-on:click="show" style="margin-top: 10px" class="startt">
            Passer le test
          </button>
        </div>
      </div>
      <div class="nt2">
        <span style="text-align: center; display: block; font-weight: bold; color: red"
          >Comment ça marche? :</span
        ><br />
        <div
          v-for="ann in annonce"
          :key="ann.id"
          :style="
            ann.niveau == 'video'
              ? 'position: relative;width: 90%;height: 0;padding-bottom: 56.25%;margin:auto'
              : ''
          "
        >
          <iframe
            v-if="ann.niveau == 'video'"
            style="position: absolute; top: 0; left: 0; width: 100%; height: 100%"
            :src="ann.image + '?autoplay=1'"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen
          ></iframe>
        </div>
        <span
          style="
            text-align: center;
            display: block;
            font-weight: bold;
            color: red;
            margin: 10px 0;
          "
          >Nos Promotions :</span
        ><br />
        <span
          v-for="ann in annonce"
          :key="ann.id"
          style="text-align: center; width: 100%; display: block"
        >
          <a
            v-if="ann.niveau == (niveau == 3 ? 'Juniors' : '')"
            :href="'https://api.victory-partners.ma/victory%20api/upload/' + ann.image"
            target="_blank"
          >
            <img
              style="width: 99%"
              :src="'https://api.victory-partners.ma/victory%20api/upload/' + ann.image"
              alt=""
            />
          </a>
          <a
            v-if="ann.niveau == (niveau == 4 ? 'Juniors' : '')"
            :href="'https://api.victory-partners.ma/victory%20api/upload/' + ann.image"
            target="_blank"
          >
            <img
              style="width: 99%"
              :src="'https://api.victory-partners.ma/victory%20api/upload/' + ann.image"
              alt=""
            />
          </a>
          <a
            v-if="ann.niveau == (niveau == 5 ? 'Juniors' : '')"
            :href="'https://api.victory-partners.ma/victory%20api/upload/' + ann.image"
            target="_blank"
          >
            <img
              style="width: 99%"
              :src="'https://api.victory-partners.ma/victory%20api/upload/' + ann.image"
              alt=""
            />
          </a>
        </span>
        <span style="text-align: center; width: 100%; display: block; font-size: 18px">
          pour plus d'nformation sur nos promotions visiter:<br />
          <a
            style="color: blue; text-decoration: underline"
            href="https://victory-partners.ma/"
            >Notre site web</a
          >
        </span>
      </div>
    </div>

    <!--<div>
            <h2>Informations sur le test:</h2>
            <table class="table" border="1">
                <tr><th>Time</th><td colspan="2">{{time}}min</td></tr>
                <tr><th>Compréhension</th><td>{{cmp}} Questions</td><td>1point / sous_question</td></tr>
                <tr><th>QSM</th><td>{{qsm}} Questions</td><td>1point / question</td></tr>
                <tr><th>Audio</th><td>{{audio}} Questions</td><td>1point / sous_question</td></tr>
                <tr><th>Note de reussir</th><td colspan="2">{{note}}</td></tr>
            </table>
            <div class="button"><button v-on:click="show">Passer le test</button></div>
        </div>-->
  </div>
</template>

<script>
window.onbeforeunload = null;
//import router from '@/router';
import router from "@/router";
import { Vue, Options } from "vue-class-component";
import { Rest } from "../providers/rest";
@Options({
  name: "Main",
  data() {
    return {
      niv: "",
      id: this.$route.params.id,
      lang: this.$route.params.lang,
      alert: false,
      dalert: false,
      isValid: false,
      pass: null,
      age: null,
      load: true,
      Nload: false,
      niveau: null,
      time: null,
      cmp: null,
      qsm: null,
      audio: null,
      already: true,
      idNv: null,
      lblNv: null,
      EnlblNv: null,
      success: null,
      note: null,
      niveaux: [],
      annonce: [],
      matiere: {},
      cant: false,
    };
  },
  beforeMount: function () {
    new Rest().getAnnonce().then((r) => {
      if (r.data.success) {
        this.annonce = r.data.data;
      }
    });
    new Rest().getMatieres().then((rr) => {
      if (rr.data.success) {
        let notfound = false;
        for(const m of rr.data.data){
          if(m.idMatiere == this.lang){
            this.matiere = m;
            notfound = true;
            return;
          }
        }
        if(notfound == false){
          router.push("/notFound");
        }
      }
    });
    new Rest()
      .ClientIsExist(this.id)
      .then((r) => {
        if (r.data.success) {
          if (r.data.isvalid > 0) {
            new Rest()
              .getClient(this.id)
              .then((client) => {
                if (client.data.success) {
                  const age =
                    new Date().getFullYear() -
                    new Date(client.data.data.DateClient).getFullYear();
                  this.age = age;
                  let niveau;
                  const lang = this.lang;
                  if (age <= 10) {
                    niveau = 3;
                    new Rest().getNiveaux("jun").then((niv) => {
                      this.niveaux = niv.data.data;
                      const nv = niv.data.data;
                      new Rest().alreadyTested(this.id, lang).then((rr) => {
                        if (rr.data.success) {
                          const dd = rr.data.data;
                          let success = "true";
                          if(dd.length == 0){
                            this.idNv = nv[0].idNiveau;
                            this.lblNv = nv[0].LibelleNiveau;
                            this.EnlblNv = nv[0].EnLibelleNiveau;
                            this.success = success;
                            this.already = false;
                          }
                          for (const d of dd) {
                            let test = false;
                            for (const n of nv) {
                              if(test == true){
                                this.idNv = n.idNiveau;
                                this.lblNv = n.LibelleNiveau;
                                this.EnlblNv = n.EnLibelleNiveau;
                                this.success = success;
                                this.already = false;
                                test = false;
                              }
                              if (n.idNiveau == d.niveau) {
                                test = true;
                                success = d.succes;
                              }
                            }
                          }
                        } else {
                          alert(rr.data.msg);
                          router.push("/notFound");
                        }
                      });
                    });
                  } else if (age > 10 && age <= 16) {
                    niveau = 4;
                    new Rest().getNiveaux("ado").then((niv) => {
                      this.niveaux = niv.data.data;
                      const nv = niv.data.data;
                      new Rest().alreadyTested(this.id, lang).then((rr) => {
                        if (rr.data.success) {
                          const dd = rr.data.data;
                          let success = "true";
                          console.log(dd)
                          if(dd.length == 0){
                            this.idNv = nv[0].idNiveau;
                            this.lblNv = nv[0].LibelleNiveau;
                            this.EnlblNv = nv[0].EnLibelleNiveau;
                            this.success = success;
                            this.already = false;
                          }
                          for (const d of dd) {
                            let test = false;
                            for (const n of nv) {
                              if(test == true){
                                this.idNv = n.idNiveau;
                                this.lblNv = n.LibelleNiveau;
                                this.EnlblNv = n.EnLibelleNiveau;
                                this.success = success;
                                this.already = false;
                                test = false;
                              }
                              if (n.idNiveau == d.niveau) {
                                test = true;
                                success = d.succes;
                              }
                            }
                          }
                        } else {
                          alert(rr.data.msg);
                          router.push("/notFound");
                        }
                      });
                    });
                  } else {
                    niveau = 5;
                    new Rest().getNiveaux("adu").then((niv) => {
                      this.niveaux = niv.data.data;
                      const nv = niv.data.data;
                      new Rest().alreadyTested(this.id, lang).then((rr) => {
                        if (rr.data.success) {
                          const dd = rr.data.data;
                          //console.log("data",dd)
                          //console.log("niveaux",nv)
                          let success = "true";
                          if(dd.length == 0){
                            this.idNv = nv[0].idNiveau;
                            this.lblNv = nv[0].LibelleNiveau;
                            this.EnlblNv = nv[0].EnLibelleNiveau;
                            this.success = success;
                            this.already = false;
                          }
                          for (const d of dd) {
                            let test = false;
                            for (const n of nv) {
                              if(test == true){
                                //console.log("2",n.idNiveau)
                                this.idNv = n.idNiveau;
                                this.lblNv = n.LibelleNiveau;
                                this.EnlblNv = n.EnLibelleNiveau;
                                this.success = success;
                                this.already = false;
                                test = false;
                              }
                              if (n.idNiveau == d.niveau) {
                                test = true;
                                success = d.succes;
                                //console.log("1",n.idNiveau)
                              }
                            }
                          }
                        } else {
                          alert(rr.data.msg);
                          router.push("/notFound");
                        }
                      });
                    });
                  }
                  this.niveau = niveau;
                  new Rest()
                    .getTestSettings(niveau)
                    .then((settings) => {
                      if (settings.data.success) {
                        this.time = settings.data.data.DureeTest;
                        this.cmp = settings.data.data.NbreQComp;
                        this.qsm = settings.data.data.NbreQCM;
                        this.audio = settings.data.data.NbreQAudio;
                        this.note = settings.data.data.reussir_Note;
                        if (this.$route.params.start == "start") {
                          this.load = true;
                          const niveau = this.idNv;
                          const lang = this.lang;
                          const data = {
                            client: this.id,
                            matiere: lang,
                            niveau: niveau,
                          };
                          new Rest().startTest(data).then((r) => {
                            if (r.data.success) {
                              this.load = false;
                              router.replace({
                                name: "Test",
                                params: {
                                  niveau: niveau,
                                  lang: lang,
                                  time: this.time,
                                  audio: this.audio,
                                  qcm: this.qsm,
                                  comp: this.cmp,
                                  note: this.note,
                                  idTest: r.data.id,
                                  idClient: this.id,
                                },
                              });
                            }
                          });
                        }
                      }
                    })
                    .catch((e) => router.push("/notFound"));
                  this.load = false;
                } else {
                  router.push("/notFound");
                }
              })
              .catch((e) => router.push("/notFound"));
            new Rest()
              .clientIsValid(this.id)
              .then((v) => {
                if (v.data.success) {
                  if (v.data.isvalid == 1) {
                    this.isValid = true;
                  } else {
                    this.isValid = false;
                  }
                } else {
                  this.isValid = false;
                }
              })
              .catch((e) => (this.isValid = false));
          } else {
            router.push("/notFound");
          }
        } else {
          router.push("/notFound");
        }
      })
      .catch((e) => router.push("/notFound"));
  },
  created() {
    if (this.$route.params.start == "start") {
      setTimeout(() => {
        this.load = true;
        const niveau = this.idNv;
        const lang = this.lang;
        const data = {
          client: this.id,
          matiere: lang,
          niveau: niveau,
        };
        new Rest().startTest(data).then((r) => {
          if (r.data.success) {
            this.load = false;
            router.replace({
              name: "Test",
              params: {
                niveau: niveau,
                lang: lang,
                time: this.time,
                audio: this.audio,
                qcm: this.qsm,
                comp: this.cmp,
                note: this.note,
                idTest: r.data.id,
                idClient: this.id,
              },
            });
          }
        });
      }, 1000);
    }
    console.log("gg")
  },
  methods: {
    show: function () {
      this.alert = true;
    },
    hide: function () {
      this.alert = false;
    },
    showCant: function () {
      this.cant = true;
    },
    hideCant: function () {
      this.cant = false;
    },
    start: function () {
      const niveau = this.idNv;
      const lang = this.lang;
      const data = {
        client: this.id,
        matiere: lang,
        niveau: niveau,
      };
      new Rest().startTest(data).then((r) => {
        if (r.data.success) {
          router.replace({
            name: "Test",
            params: {
              niveau: niveau,
              lang: lang,
              time: this.time,
              audio: this.audio,
              qcm: this.qsm,
              comp: this.cmp,
              note: this.note,
              idTest: r.data.id,
              idClient: this.id,
            },
          });
        } else {
          alert(r.data.msg);
        }
      });
    },
    startNv: function () {
      const niveau = this.niv;
        const lang = this.lang;
        const data = {
          client: this.id,
          matiere: lang,
          niveau: niveau,
        };
        new Rest().startTest(data).then((r) => {
          if (r.data.success) {
            router.replace({
              name: "Test",
              params: {
                niveau: niveau,
                lang: lang,
                time: this.time,
                audio: this.audio,
                qcm: this.qsm,
                comp: this.cmp,
                note: this.note,
                idTest: r.data.id,
                idClient: this.id,
              },
            });
          } else {
            alert(r.data.msg);
          }
        });
      
    },
  },
})
export default class Main extends Vue {}
</script>

<style>
.durre {
  position: absolute;
  left: 30px;
  bottom: 230px;
  color: yellow;
  font-weight: bold;
}
#nav {
  display: flex;
}
#Mimg {
  width: 70%;
  box-shadow: 1px 1px 9px 0;
  cursor: pointer;
}
.Mradio {
  display: grid;
  grid-template-columns: 50% 50%;
}
@media screen and (max-width: 768px) {
  .durre {
    position: initial;
    margin: auto;
    text-align: center;
  }
  #Mimg {
    width: 100%;
  }
  #nav {
    text-align: center;
    display: block;
  }
  #nav ul li {
    float: none;
  }
  #nav ul {
    padding-right: 0;
  }
  #logo {
    padding: 0;
    width: 100%;
  }
  #logo img,
  #logo span {
    margin: auto;
  }
  .alert .alr {
    background-image: none !important;
  }
  .alr div {
    width: 100% !important;
    padding: 0 !important;
    max-width: unset !important;
  }
  .alr .x {
    width: fit-content !important;
    padding: 0 !important;
  }
  .Mradio {
    grid-template-columns: 100%;
  }
}
@media screen and (max-width: 1200px) {
  .cnttt {
    display: block !important;
    margin: 20px 0;
    padding: 10px 0 !important;
  }
  .nt2 {
    width: 100% !important;
    padding: 0 !important;
    padding-top: 10px !important;
    border-left: none !important;
    border-top: 1px dashed black;
    text-align: center !important;
  }
  .nt2 img {
    width: 80% !important;
  }
  .cnttt div:nth-child(1) {
    width: 90% !important;
    margin: auto !important;
  }
  .cnttt div:nth-child(1) img {
    margin: 20px 0 !important;
  }
  /*.alert .alr {
    background-image: none !important;
  }
  .alr div {
    width: 100% !important;
    padding: 0 !important;
  }
  .alr .x {
    width: fit-content !important;
    padding: 0 !important;
  }*/
}
.title {
  text-align: center !important;
  margin-top: 50px !important;
}
.cnttt {
  display: flex;
}
.nt2 {
  width: 30%;
  padding: 20px 15px;
  border-left: 1px dashed black;
}
.cnttt div:nth-child(1) {
  width: 70%;
}
.table {
  width: 70%;
  margin: auto;
  margin-top: 10px;
  border-collapse: collapse;
}
.table tr th {
  width: 40%;
}
.table tr td {
  width: 30%;
}
.table td,
.table th {
  width: 50%;
  text-align: center;
  padding: 10px;
}
.button {
  text-align: center !important;
  margin-top: 50px !important;
}
.button button {
  padding: 10px 50px !important;
  background-color: #4edc54 !important;
  font-weight: bold !important;
  font-size: 20px !important;
  cursor: pointer !important;
}
.alert {
  background-color: #ffffff9c !important;
  width: 100% !important;
  height: 100vh !important;
  position: fixed !important;
  top: 0 !important;
  left: 0 !important;
  z-index: 2 !important;
  display: flex !important;
  align-items: center !important;
}
.alert .alr {
  width: 600px !important;
  height: 500px !important;
  background-color: #0e95d3 !important;
  background-image: url("../assets/popup.png");
  background-position: center center !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
  margin: auto !important;
  border-radius: 20px !important;
  position: relative !important;
  padding: 25px 0 !important;
}
.alert .alr .x {
  position: absolute !important;
  top: 0 !important;
  right: 0 !important;
  color: white !important;
  font-weight: bold !important;
  font-size: 20px !important;
  padding: 5px 10px !important;
  text-align: center !important;
  border-top-right-radius: 20px !important;
  cursor: pointer !important;
}
.alert .alr .x:hover {
  background-color: red !important;
}
.alr .childd {
  text-align: center;
  color: white;
  margin: 10px 0 20px 0;
}
.alrTitle {
  font-weight: bold !important;
  font-size: 20px !important;
}
.alr .childd input,
.childd select {
  width: 70%;
  padding: 5px;
}
.alr .childd input::placeholder {
  text-align: center !important;
  font-size: 15px !important;
}
.startt {
  border: none !important;
  margin-bottom: 10px !important;
  padding: 7px 10px !important;
  background-color: #4edc54 !important;
  cursor: pointer !important;
  font-weight: bold !important;
  font-size: 20px !important;
}
.remarque {
  width: fit-content !important;
  margin: 10px auto !important;
  align-items: center !important;
  display: flex !important;
  color: yellow !important;
  font-weight: bold !important;
  font-size: 20px !important;
}
.remarque img {
  margin: 0 5px !important;
}
.lds-ring div {
  border-color: red transparent transparent transparent !important;
}
.error {
  color: #fff700 !important;
}
</style>